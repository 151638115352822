.square-box {
  position: relative;
}

.square-box:before {
  content: "";
  display: block;
  padding-top: 50%;
}

.square-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}