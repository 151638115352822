.light {
  background-color: white;
}

.footerMenu {
  text-align: left;
  position: relative;
  top: 5px;
}

@media only screen and (max-width: 680px) {

  .menuSeparator {
    display: block;
    height: 0;
    visibility: hidden;
  }

  .footerMenu {
    top: 0px;
  }
}

.contactModal {
  min-width: 450px;
}