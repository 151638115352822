@import "../styles/fonts.scss";

.logo {
  position: relative;
  top: -3px;
}

@media only screen and (max-width: 400px) {
  
  .logo {
    left: -30px;

    img {
      height: 45px;
    }
  }
}