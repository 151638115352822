.light,
.light a {
  background-color: white;
  color: black;

  .menu > * {
    color: black;
  }
}

.dark,
.dark a {
  color: white;

  .menu > * {
    color: white;
  }
}

.menu > * {
  color: black;
}

.menu a {
  padding: 0 20px;
}

.menu a:nth-child(1) {
  padding-left: 0;
}

.menu a:nth-last-child(1) {
  padding-right: 0;
}

.menu > * {
  color: black;
}

@media only screen and (max-width: 400px) {
  
  .menu {
    position: relative;
    right: -30px;
  }
}

.accountMenuCol {
  min-width: 50px;
  text-align: center;
  margin-left: 50%;
}

.accountMenu {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .05), 0 6px 20px 0 rgba(0, 0, 0, .05);
  margin-top: 10px;
  min-width: 120px;

  &:global {
    
    &.ant-menu-root.ant-menu-vertical {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .05), 0 6px 20px 0 rgba(0, 0, 0, .05);
    }
  }
}

:global {

  .ant-menu-submenu-title[title="Organizations"] {

    .ant-menu-submenu-arrow {
      float: left;
      left: -14px;
      position: relative;
      transform: scale(-1, 1);
    }
  }
}